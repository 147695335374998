@import url(https://fonts.googleapis.com/css?family=Open+Sans:regular,semibold,bold&display=swap);


body {
  overflow: hidden;
}

main {
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

